<template>
  <v-container>
    <v-row>
      <v-col style="display: flex">
        <v-btn large icon :to="{ name: 'Sectores' }"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <span class="text-h4">Detalle de Sector</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br />

    <div v-if="sector">
      <SectorCard :sector="this.sector"> </SectorCard>
    </div>
  </v-container>
</template>

<script>
import SectorCard from "../components/SectorCard.vue";
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "SectorDetalle",
  components: {
    SectorCard,
  },
  data() {
    return {
      sector: null,
    };
  },
  props: ["id"],
  created() {
    EmpleadosService.getSector(this.id)
      .then((response) => {
        this.sector = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
